.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* ==========SignIn=========== */

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}


/* --- */


html,
body {
  height: 100%;
}

.loginbody {
  display: flex;
  width: 50%;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  /* max-width: 366px; */
  max-width: 80%;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.forminputemail {
  margin-bottom: -1px !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.forminputpassword {
  margin-bottom: 10px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

button.btn.btn-subbtn.btn-primary {
  width: 100%;
}

.form-check {
  display: inline-block !important;
}

.rowcontainlogin {
  width: 100%;
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
}

.imgsize {
  height: 100vh;
}

.customCaption {
  position: absolute;
  right: 15%;
  bottom: 42%;
  top: 42%;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
  background-color: black;
}

.searchBox .form-control{
  background: #00800026;
  border-radius: 20px !important;
}

.link-icon{
  width: 3.2em;
  height: 2em;
}