body {
    font-size: .875rem;
    /* background: #F7F8FC !important; */
    background: rgb(148 246 0 / 2%);
}

.logo {
    width: 55%;
}

.feather {
    width: 16px;
    height: 16px;
}

/*
   * Sidebar
   */

.sidebar {
    position: fixed;
    top: 0;
    /* rtl:raw:
    right: 0;
    */
    bottom: 0;
    /* rtl:remove */
    left: 0;
    z-index: 100;
    /* Behind the navbar */
    padding: 1.6rem 0 0;
    /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

@media (max-width: 767.98px) {
    .sidebar {
        top: 5rem;
    }
}

.sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto;
    /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
    font-weight: 500;
    /* color: rgb(0, 0, 0); */
    background-color: rgb(212, 212, 212);
    padding: 10px 20px;
    margin-bottom: 0.02rem;
}

.sidebar .nav-link:hover {
    background-color: #00000047;
    color: #fff;
}

.nav-link.active svg { 
    color: #fff;
}
.nav-link svg {
    width: 1rem;
    height: 1rem;
    color: #333;
    margin: 0 1vw;
}

.sidebar {
    background-color: #f8f9fa !important;
}

.sidebar .nav-link {
    font-weight: 500;
    color: rgb(0, 0, 0);
    background-color: #00800026;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    margin-right: 1rem;
    border-top-right-radius: 8rem;
    border-bottom-right-radius: 8rem;
}

.sidebar .nav-link .feather {
    margin-right: 4px;
    color: #727272;
}

.sidebar .nav-link.active {
    color: #fff;
    background-color: #0d9700;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
    color: inherit;
}

.sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
}

.navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    background-color: rgba(0, 0, 0, .25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .navbar-toggler {
    top: .25rem;
    right: 1rem;
}

.form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.thubnail {
    width: 100px;
}

.bookRow {
    vertical-align: middle;
}

td label {
    font-weight: 600;
    color: #252733;
}

td p{
    font-weight: 600;
    color: #252733;
    height: 300px;
    overflow-y: scroll;
}

.title {
    font-family: "Myanmar Sans Pro";
}

.editIcon svg {
    width: 1.5em;
    height: 1.5em;
    cursor: pointer;
    margin: 1rem;
}

.logoutbtn {
    background-color: rgba(172, 255, 47, 0.179);
    padding: 0.5rem 1rem;
    border-radius: 8rem;
}

.logouttxt {
    text-decoration: none;
}

.customnav {
    background-color: rgb(172 255 47 / 4%) !important;
}

.btn-primary {
    color: #fff;
    background-color: #0e9700;
    border-color: #2d8900;
    border-radius: 1.5rem;
    padding: 0.5rem 1rem;
}

.btn-primary:hover {
    color: #333;
    background-color:#00800026;
    border-color: #00800026;
}

.dashboardheading {
    margin-left: 1.5rem;
}

.slagcolor {
    color: #00800026 !important;
}

.mticn {
    width: 100%;
}

.mticncontainer {
    position: absolute;
    bottom: 0;
    opacity: .8;
    color: #0e9700;
}

.datacontainer {
    overflow: scroll;
    overflow-x: hidden;
    margin-bottom: 2rem;    
    margin-left: 0px;
    margin-right: 0px;
    max-width: 100% !important;
    height: calc(100vh - 120px);
}

.row>* {
    margin-top: calc(var(--bs-gutter-x) * .5);
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #0e9700;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, .5);
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #dcf7d5;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, .4);
}